import React, { useEffect, useState } from 'react';
import './SearchBar.css';
import { Calendar } from 'primereact/calendar';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faCalendar, faFileText, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { eventBus } from '../../../shared/eventBus';

function SearchBar() {
	const [selectedTipo, setSelectedTipo] = useState(null);
  const [selectedAcao, setSelectedAcao] = useState(null);
  const [observacao, setObservacao] = useState('');
  const [date, setDate] = useState(null);

  const [filtro, setFiltro] = useState(
    {
      obs: '',
      acao: '',
      tipo: '',
      date: undefined
    }
  );
  
  const tipos = [
      { name: 'Facial', code: 'Facial' },
      { name: 'Placa', code: 'Vehicle' },
  ];

  const acaos = [
    { name: 'Resolvido', code: 'RESOLVIDO' },
    { name: 'Alarme falso', code: 'FALSO' },
    { name: 'Encaminhado', code: 'ENCAMINHADO' },
];


  useEffect(() => {
    
    if (selectedAcao) {
      filtro.acao = (selectedAcao as any).code
    } else {
      filtro.acao = ''
    }

    if (selectedTipo) {
      filtro.tipo = (selectedTipo as any).code
    } else {
      filtro.tipo = ''
    }

    if (observacao) {
      filtro.obs = observacao
    } else {
      filtro.obs = ''
    }

    if (date) {
      filtro.date = date
    } else {
      filtro.date = undefined
    }

    setFiltro(filtro)
    eventBus.dispatch('search-bar', filtro)
  }, [selectedAcao, selectedTipo, filtro, observacao, date]);
  
  const handleSelectTipo = (evento: any) => {

    if (evento.value) {
      setSelectedTipo(evento.value)
    } else {
      setSelectedTipo(null)
    }

  }

  const handleSelectAcao = (evento: any) => {
    
    if (evento.value) {
      setSelectedAcao(evento.value)
    } else {
      setSelectedAcao(null)
    }
    
  }

  const handleInputObservacao = (evento: any) => {

    if (evento.target.value) {
      setObservacao(evento.target.value)
    } else {
      setObservacao('')
    }
    
  }

  const handleDateSelect = (evento: any) => {

    if (evento.target.value) {
      setDate(evento.target.value)
    } else {
      setDate(null)
    }
    
  }

  return (

    <nav id="search-bar">
      <div className='nav-search-bar col-8' id="search-bar-container">
        <div>
          <FontAwesomeIcon onClick={() =>     eventBus.dispatch('search-bar', filtro)}  icon={faRefresh}></FontAwesomeIcon>
          <span onClick={() =>     eventBus.dispatch('search-bar', filtro)} className='reload'>Recarregar</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          <Dropdown showClear={true} value={selectedTipo} onChange={(e) => { handleSelectTipo(e) }} options={tipos} optionLabel="name" placeholder="Tipo" className="searchbar-dropdown md:w-14rem field" />
        </div>
        <div>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
          <Dropdown showClear={true} value={selectedAcao} onChange={(e) => { handleSelectAcao(e) }} options={acaos} optionLabel="name" placeholder="Status" className="searchbar-dropdown md:w-14rem field" />
        </div>
        <div>
          <FontAwesomeIcon icon={faFileText}></FontAwesomeIcon>
          <input type="text" value={observacao} onChange={(e) => { handleInputObservacao(e) }} className='search-bar-field field' placeholder='Descrição' />
        </div>
        <div>
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
          <Calendar showButtonBar={true} dateFormat='dd/mm/yy' className='search-bar-calendar field' value={date} placeholder='Data' onChange={(e: any) => handleDateSelect(e)} />
        </div>
        
      </div>
    </nav>
  );
}

export default SearchBar;
