import React, { useEffect, useState } from 'react';
import './Alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { faCheck, faBan, faMailForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Galleria } from 'primereact/galleria';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useHistoryAlertApi, HistoryAlert } from '../../hooks/historyAlertApi';
import { eventBus } from '../../shared/eventBus';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useLoginApi } from '../../hooks/loginApi';
declare global {
  interface Window {
    electron: any; 
  }
}

function Alert() {
  const  [mails, setMails] = useState<any>([])
  const  [mail, setMail] =  useState<any>(null)
  const  [alerts, setAlerts] =  useState([])
  const  [tipo, setTipo] =  useState('')
  const {createHistoryDataRow} = useHistoryAlertApi() 
  
	const  [observacao, setObservacao] =  useState('');
  const  [dialogVisilibity, setDialogVisilibity] =  useState(false);
  const  [encaminhamentoDialogVisilibity, setEncaminhamentoDialogVisilibity] =  useState(false);
  const  [acao, setAcao] =  useState({acao: '', observacao: ''});
  const { id } = useParams();
  const navigate = useNavigate();

  const {validMails} = useLoginApi()

  const ipcMessage = async () => {
    const dadosRecebidos = await window.electron.obterDadosAlert(id)
    setTipo(dadosRecebidos.tipo)
    setAlerts(dadosRecebidos.dados)
  }
 
  useEffect(() => {
   getMails()
   //setMails([{mail: 'daniel.sasaki@intelicitybr.com.br'}, {mail: 'gabriel.godoybz@gmail.com'}, {mail: 'gabriel.godoybz@hotmail.com'}])
   ipcMessage()
  }, []); 
  
  const handleResolvidoButton = async () => {
      acao.acao = 'RESOLVIDO'
      acao.observacao = observacao 
      setAcao(acao)
      setDialogVisilibity(true)
  } 

  const handleAlarmeFalsoButton = () => {
    acao.acao = 'FALSO'
    acao.observacao = observacao 
    setAcao(acao)
    acao.observacao = observacao
    setDialogVisilibity(true)
  } 

  const getMails = async () => {
    const mails = await validMails()
    const labels = mails.map((email: string) => {return {mail: email}})
    setMails(labels)
  }

  const handleEncaminharButton = () => {
    acao.acao = 'Encaminhamento'
    acao.observacao = observacao 
    setAcao(acao)
    setEncaminhamentoDialogVisilibity(true)
    
  } 

  const handleObsInput = (event: any) => {
    setObservacao(event.target.value)
  }  

const accept = async () => { 
  const request: HistoryAlert = {
    id: null,
    observacao: acao.observacao,
    acao: acao.acao,
    tipo: tipo,
    mail: mail ? mail.mail : null,
    dt_alerta: new Date(),
    anexos: alerts.map((img: any) => ({
      face: img.img,
      dt_insercao: new Date()
    })),
  };
 
  eventBus.dispatch('loader', true)
  try {
    await createHistoryDataRow(request)
    eventBus.dispatch('loader', false)
    eventBus.dispatch('toast', {type: 'ok', message: 'Enviado com sucesso!'})
    await removeAlert()
    navigate('/alert-list')
  } catch(error) {
    console.log(error)
    eventBus.dispatch('loader', false)
    eventBus.dispatch('toast', {type: 'error', message: 'Erro ao enviar'})
  }
  
}

const handleDialogEncaminhamentoClose = () => {
  setEncaminhamentoDialogVisilibity(false); 
  setMail(null);
}

const reject = () => {
  console.log(acao)
}

const itemTemplate = (item: any) => {
  return <img src={item.img} className='imgTemplate' alt='' style={{ width: '100%' }} />
} 

const thumbnailTemplate = (item: any) => {
  return <img src={item.img} alt='' />
}

const removeAlert = async () => {
  await window.electron.alertRemove(id)
}

const handleSelectMail = (evento: any) => { 
  if (evento.value && evento.value.mail) {
    setMail(evento.value)
  } else {
    setMail(null)
  }

}

const handleEncaminhamento = async () => {
  setEncaminhamentoDialogVisilibity(false)
  accept()
}

  return (
    <div id='alert-container' className="App" style={{'height': '100%'}}>
      
      <div className='card-alert-container'>
        
        <div className='card-alert-container-header'>
          <h2>Alerta</h2>
        </div>
        
        <>
          {
            alerts && alerts.length > 0 ?
            <div className='card-alert-container-body'>
              <div className='img-alert-container'>
              <Galleria item={itemTemplate} thumbnail={thumbnailTemplate} value={alerts} numVisible={5} style={{ maxWidth: '550px' }}  />
              </div> 
              <div className='footer'>
                <div className='col-12 obs-container'>
                    <label>Observação</label>
                    <textarea className='obs' onChange={handleObsInput}></textarea>
                </div>
                <div className='col-12 action-button-container'>
                  <button id='ok' className='btn btn-primary' onClick={handleResolvidoButton}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>Resolvido</button>
                  <button id='false' className='btn btn-danger' onClick={handleAlarmeFalsoButton}><FontAwesomeIcon icon={faBan}></FontAwesomeIcon>Falso</button>
                  <button id='forward' className='btn btn-secondary' onClick={handleEncaminharButton}><FontAwesomeIcon icon={faMailForward}></FontAwesomeIcon>Encaminhar</button>
                </div> 
              </div>
            </div>
            : ''
          }
        </>
          
    
      </div>

      <Dialog header="Selecione um E-mail" visible={encaminhamentoDialogVisilibity} style={{ width: '50vw' }} onHide={() => handleDialogEncaminhamentoClose() }>
        <Dropdown id='maildropdown' value={mail} onChange={handleSelectMail} showClear={true} optionLabel="mail" options={mails} placeholder="Tipo" className="col-12 md:w-14rem field" />
        <div className='col-12'> 
          <button onClick={handleEncaminhamento} disabled={!mail} className='btn btn-primary btn-encaminhar'>Encaminhar</button>
        </div>
      </Dialog>

      <ConfirmDialog visible={dialogVisilibity} onHide={() => setDialogVisilibity(false)} message="Deseja confirmar?" 
                header="Confirmação" acceptLabel='Sim' rejectLabel='Não' icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
      
    </div>
    
  );
}

export default Alert;
