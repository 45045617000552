import axios from '../axios/axiosConfig';
import axiosAPI from 'axios'
export interface LoginRequest {
  email: string,
  senha: string
}

export interface LoginRegistryRequest {
  key: string,
  dados: any
}

export const useLoginApi = () => {
    const backendAuthUrl = 'https://segpub.inteli.city/node/api/auth'
    
    const loginSubmit = async (loginRequest: LoginRequest): Promise<any> => {
        let result = null
        const responseData = await axiosAPI.post(`${backendAuthUrl}/login`, loginRequest)
        result = responseData 
        
        return result
    }

    const validMails = async (): Promise<any> => {
      let result = null
      const responseData = await axiosAPI.get(`${backendAuthUrl}/mails`)
      result = responseData.data.body 
      
      return result
  }

    const loginRegistry = async (loginRegistryRequest: LoginRegistryRequest): Promise<any> => {
      let result = null
      const responseData = await axios.post(backendAuthUrl, loginRegistryRequest)
      result = responseData 
      
      return result
    }

    return {
      loginSubmit, validMails,loginRegistry
    }
}