import { useEffect, useState } from 'react';
import './Auditoria.css';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useAuditoriasApi } from '../../hooks/historyAuditorias';
import { eventBus } from '../../shared/eventBus';
import { TabPanel, TabView } from 'primereact/tabview';

import { Galleria } from 'primereact/galleria';
import { useHistoryAlertApi } from '../../hooks/historyAlertApi';
import History from '../history/History';

declare global {
  interface Window {
    electron: any; 
  }
}

export interface History {
  acao: any;
  createdAt: any;
  dt_alerta: any;
  mail: any;
  history_alert_anexos: any;
  id: any;
  observacao: any;
  tipo: any;
  updatedAt: any;
  user_id: any;
}

function Auditoria() { 
 
  const {search, enviaNovaObs} = useAuditoriasApi()
  const {queryAlert} = useHistoryAlertApi()
  const { id } = useParams();
  const [auditorias, setAuditorias] = useState([])
  const [novaObs, setNovaObs] = useState('') 
  const [alert, setAlert] = useState(({})) 
  const  [alerts, setAlerts] =  useState([])

  const itemTemplate = (item: any) => {
    return <img src={item.face} className='imgTemplate' alt='' style={{ width: '100%' }} />
  } 
  
  const thumbnailTemplate = (item: any) => {
    return <img src={item.face} alt='' />
  }

  const getAuditorias = async () => { 
    const result = await search(parseInt(id!.toString(), 0))
    setAuditorias(result.data.body)
  }

  const getAlert = async () => {
    eventBus.dispatch('loader', true)
    const obj = await queryAlert(id)
    setAlerts(obj.data[0].history_alert_anexos)
    setAlert(obj.data[0])
    eventBus.dispatch('loader', false)
  }

  useEffect(() => {
    getAuditorias()
    getAlert()
  }, []);  

  

  const dateBrFormat = (date: Date): string => {
    const dataFormatada = format(date, "dd 'de' MMMM 'de' yyyy HH:mm", { locale: ptBR });
    return dataFormatada
  } 

  const handleNovaObs = ($event: any) => { 
    setNovaObs($event.target.value)
  }

  const handleEnviaNovaObs = async () => {
    
    eventBus.dispatch('loader', true)
    try {
      await enviaNovaObs(id, novaObs)
      eventBus.dispatch('loader', false)
      eventBus.dispatch('toast', {type: 'ok', message: 'Enviado com sucesso!'})
      getAuditorias()
    } catch(error) {
      console.log(error)
      eventBus.dispatch('loader', false)
      eventBus.dispatch('toast', {type: 'error', message: 'Erro ao enviar'})
    }

  }

  const formatDate = (date: string) => {
    const dataString = '2023-10-05T14:22:01.766Z';
    const dataObj = parseISO(dataString);
    const dataFormatada = format(dataObj, 'dd/MM/yyyy HH:mm');
    return dataFormatada
  }
  
  return (
    <div className="App screen-grid" style={{'height': '100%'}}>
      <div className='col-12'>
        <h1 className='title-list pt-10'>Auditoria</h1>
      </div>
      <div className='col-12'>
        <TabView>
          <TabPanel header="Detalhes">
            <div className='col-12 detail-panel'>
            
              <div className='col-5 full-centered flex-start'>
                <Galleria className='galeria-detalhes' item={itemTemplate} thumbnail={thumbnailTemplate} value={alerts} numVisible={5}   />
              </div>
            
              <div className='col-7 form-group full-centered flex-start'>
                 <div className='form-data col-6'>
                    <label>Status</label>
                    <input readOnly={true} value={(alert as History).acao}/>
                 </div>
                 <div className='form-data col-6'>
                    <label>Tipo</label>
                    <input readOnly={true} value={(alert as History).tipo}/>
                 </div>
                 <div className='form-data col-6'>
                    <label>Data</label>
                    <input readOnly={true} value={formatDate((alert as History).dt_alerta)}/>
                 </div>

                 {
                  (alert as History).history_alert_anexos && (alert as History).history_alert_anexos[0].anprData?
                  <>
                   <div className='form-data col-6'>
                    <label>Placa</label>
                    <input readOnly={true} value={(alert as History).history_alert_anexos[0].anprData}/>
                   </div>
                  </>
                  :
                  <></>
                 }

                 {
                  (alert as History).mail ?
                  <>
                   <div className='form-data col-6'>
                    <label>E-mail encaminhamento</label>
                    <input readOnly={true} value={(alert as History).mail}/>
                   </div>
                  </>
                  :
                  <></>
                 }
                
              </div>

              <div className='col-12 obs-area full-centered flex-start flex-column'>
                <label>Primeira Observação</label>
                <textarea readOnly={true} value={(alert as History).observacao}></textarea>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Histórico">
            <div className='grid-chat col-12'>
              <div className='col-12 scroll-y'>
                <>
            
                  {auditorias && auditorias.length > 0 ?
                    <>
                      {
                        auditorias.map((auditoria: any) =>
                          <>
                                <div className='auditoria-item'>
                                <div className='col-12 auditoria-date-header'>
                                  <span className='auditoria-line'></span><span className='auditoria-date-header-title '> {dateBrFormat(new Date(auditoria.dt_alerta))} </span><span className='auditoria-line'></span>
                                </div>
                                <div className='flex col-12 auditoria-chat-ballon-container'>
                                    
                                    <div className='col-12 auditoria-chat-ballon'>
                                      <div className='col-1 user-icon'>
                                        <div className='icon-bg'>
                                          <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                                        </div>
                                      </div>
                                      <div className='col-11 ballon-text'>
                                            <span className='mail'>{auditoria.app_user.email}</span>
                                            <div className='body'>{auditoria.observacao}</div>
                                      </div>
                                    </div>
                                </div>
                                </div> 
                          </>
                          )
                      }
                        
                    </>
                    :
                    <></>
                  }
              
                  
                </>
                

              </div>
              <div className='col-12 p-10 margin-chat'>
                <div className='chat-message'>
                  <div className='icon-container-chat'>

                      <FontAwesomeIcon
                      className='icon-chat' data-tooltip-id="my-tooltip-1"  icon={faImages}></FontAwesomeIcon>
                  <ReactTooltip
                      id="my-tooltip-1"
                      place="top"
                      content="Visualizar imagens"
                    />
                  </div>
                  <div className='text-container-chat'>
                    <textarea value={novaObs} onChange={handleNovaObs} placeholder='Digite aqui uma nova observação'></textarea>
                  </div>
                  <div className='icon-container-chat'>
                    
                    <div className='paper-plane' onClick={handleEnviaNovaObs}>
                      <FontAwesomeIcon data-tooltip-id="my-tooltip-2" className='icon-chat' icon={faPaperPlane}></FontAwesomeIcon>
                    </div>
                    
                    <ReactTooltip
                      id="my-tooltip-2"
                      place="top"
                      content="Enviar alteração"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      
      
      
          
     </div>
  );
}

export default Auditoria;
