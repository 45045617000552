import React, { useEffect, useState } from 'react';
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignIn } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { LoginRequest, useLoginApi } from '../../hooks/loginApi';
import { eventBus } from '../../shared/eventBus';


function Login() {
  const { id } = useParams();
	const  [acesso, setAcesso] =  useState('');
  const  [senha, setSenha] =  useState('');
  const navigate = useNavigate();
  const {loginSubmit} = useLoginApi();
  
  const handleAcessoInput = (event: any) => {
    setAcesso(event.target.value)
  }

  const handleSenhaInput = (event: any) => {
    setSenha(event.target.value)
  }

  const handleAcessoClick = async () => {
    if (acesso && senha) {
        const request = {email: acesso, senha: senha} as LoginRequest;
        
        try {
          eventBus.dispatch('loader', true)
          const result = await loginSubmit(request)
          const user = result.data.body
          localStorage.setItem('login_data', JSON.stringify(user))
          ipcMessage(JSON.stringify(user))
          eventBus.dispatch('loader', false)
          eventBus.dispatch('toast', {type: 'ok', message: 'Autenticado com sucesso'})
          navigate('history')
         /* try {
            const requestRegistry: LoginRegistryRequest = request as any
            requestRegistry.key = request.email
            requestRegistry.dados = localStorage.getItem('login_data') 
            const user = await loginRegistry(requestRegistry) 
            localStorage.setItem('user', JSON.stringify(user.data.body))
      
          } catch(error) {
            eventBus.dispatch('loader', false)
            eventBus.dispatch('toast', {type: 'Error', message: 'Erro na autenticação'})
            return
          }

          eventBus.dispatch('loader', false)
          eventBus.dispatch('toast', {type: 'ok', message: 'Autenticado com sucesso'})
          navigate('history')*/
        } catch(error) {
          console.log(error)
          eventBus.dispatch('loader', false)
          eventBus.dispatch('toast', {type: 'error', message: 'Não foi possível acessar'})
        }
        
    } else {
      eventBus.dispatch('toast', {type: 'warn', message: 'Campos incompletos'})
    }
  }
 
  
  const ipcMessage = async (dados: any) => {
    const dadosRecebidos = await window.electron.receberDadosLogin(dados)
  }

  return (
    <>
  
    
    <div className="App" style={{'height': '100%'}}>
        <div className="login-container">
          <div className="login-form">
            <div className='col-12'>
               
            </div>
            <div className='col-12 form-login-card-content'>
                <div className='col-12'>
                  <img className='logo' alt='logo' src={'/imgs/intelicity.png'}></img>
                </div>
                <div className='form-group col-10'>
                  <label>Acesso</label>
                  <div className='input-icon'>
                    <FontAwesomeIcon icon={faUser} className='icon-input' />
                    <input placeholder='Insira seu acesso' value={acesso} onChange={handleAcessoInput} className='input-login'></input>
                  </div>
                </div>
                
                <div className='form-group col-10'>
                  <label>Senha</label>
                  <div className='input-icon'>
                    <FontAwesomeIcon icon={faLock} className='icon-input' />
                    <input type='password' placeholder='Insira sua senha' value={senha} onChange={handleSenhaInput} className='input-login'></input>
                  </div>
                </div>
                <button id='accessButton' className='col-10 btn btn-primary' onClick={handleAcessoClick}> <FontAwesomeIcon className='acess-icon' icon={faSignIn} /><span>Acessar</span></button>
            </div>
            
          </div>
        </div>
    </div>
    
    </>
 
   
  );
}

export default Login;
