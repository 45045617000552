import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from './pages/login/Login';
import History from './pages/history/History';
import SideBar from './components/sidebar/SideBar';
import { useEffect, useState } from 'react';
import Alert from './pages/alert/Alert';
import Auditoria from './pages/auditoria/Auditoria';
import { ProgressSpinner } from 'primereact/progressspinner';
import { eventBus } from './shared/eventBus'
import { ToastContainer, toast } from 'react-toastify';
import AlertList from './pages/alertList/AlertList';

function App() { 
  const location = useLocation();
  const [showSideBarMenu, setShowSideBarMenu] = useState(false); 
  const  [showLoader, setLoader] =  useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {

    eventBus.on('login', (value?: any) => {
      navigate('/')
    })

    eventBus.on('loader', (value: boolean) => {
        setLoader(value)
    })

    eventBus.on('toast', (obj: ({type: string, message: string})) => {
        handleToast(obj.type, obj.message)
    })

    const indexOfPathAudit = location.pathname.indexOf('alert')
    if (location.pathname === '/' || (indexOfPathAudit && indexOfPathAudit !== -1)) {
      setShowSideBarMenu(false);
    } else {
      setShowSideBarMenu(true);
    }

    if ((location.pathname !== '/'! && !location.pathname.indexOf('alert')) && !localStorage.getItem('login_data')) {
      navigate('/')
    }
  
  }, [location, navigate]);


  const handleToast = (type: string,  message: string) => {
    
    if (type) {
      let call = null
      if (type === 'ok') {
        call = toast.success
      } else if (type === 'warn') {
        call = toast.info
      } else {
        call = toast.error
      }

      call(message)
    } else {
      toast.error('Chamada inválida')
    }
    
  }

  return (
    <>
    { 
      showSideBarMenu ?
      <>
  
        <SideBar></SideBar>
      </>
      : <></>
    }
    
    <Routes>
      <Route path="/" element={<Login />}>
      </Route>
      <Route path="/:id" element={<Login />}></Route>
      <Route path="/history" element={<History />}>
      </Route>
      <Route path="/alert/:id" element={<Alert />}>
      </Route>
      <Route path="/alert-list" element={<AlertList />}></Route>
      <Route path="/auditorias/:id" element={<Auditoria />}>
      </Route>
    </Routes>

    <div id='toastrc'>
      <ToastContainer />
    </div>
    <>
      {showLoader ? 
      
        <div id='loader'>
          
          <div>
            <ProgressSpinner />
          </div>
    
        </div>
      
      : ''}
      
    </>
    
    
    </>
  );
}

export default App;

