import './SideBar.css';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

function SideBar() {
  const navigate = useNavigate();

  const items = [
    {
        label: 'Histórico',
        items: [
            {
                label: 'Listagem',
                url:'history',
                icon: 'fa fa-table',
                command: () => {
                    navigate('history')
                }
            }
        ]
    }
  ];

  const logout = () => {
    ipcMessageLogout(null)
    localStorage.clear(); 
    navigate('/');
  }

  const ipcMessageLogout = async (dados: any) => {
    const dadosRecebidos = await window.electron.receberDadosLogin(dados)
  }

  return (
    <div className='side-bar'>
      
      <div className='col-12 full-centered'>
        <img className='logo' alt='logo' src={'/imgs/intelicity.png'} style={{'transform': 'scale(0.75)'}}></img>
      </div>
      <div className='col-12'>
        <Menu model={items} />
      </div>
      <div className='col-12 full-centered signout'>
        <button className='btn btn-primary btn-signout' onClick={logout}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon> Sair</button>
      </div>
    </div>
  );
}

export default SideBar;
