import { useEffect, useState } from 'react';
import './AlertList.css';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from 'primereact/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    electron: any; 
  }
}

function AlertList() {
  const  [alerts, setAlerts] =  useState([])
  const  [tipo, setTipo] =  useState('')

  const navigate = useNavigate();
  
  useEffect(() => {

    if (!alerts || alerts.length < 1) {
      ipcMessage()
    } else {
      
      let turn = false
      setInterval(() => {
        const card = document.getElementsByClassName('p-card')[0]
        let classe = 'p-card p-component alert-list-card md:w-25rem principal-card'

        if (!turn) {
          classe = 'p-card p-component alert-list-card md:w-25rem'
        }

        turn = !turn

        if (card) {
          card.className = classe
        }
        
      }, 400)
    }
    
  }, [alerts]);  

  const ipcMessage = async () => {
    const dadosRecebidos = await window.electron.sendData({})
    setAlerts(dadosRecebidos)
  }

  const header = (item: any) => (
    <img alt="Card" className='img-list' src={item.previewImg} />
  ) 

  const handleOpenButton = (item: any): any => {
    console.log(item)
    navigate('/alert/' + item.id)
  }

  return (
    <div id='alertlist-container' className="App" style={{'height': '100%'}}>
     <div className='alertlist-header'>
        Alertas
     </div>
     <div className='alertlist-body'>
      <>
          {
            alerts && alerts.length > 0 ? alerts.map((item: any) =>
              <> 
           
              <div key={item.id} className='col-3 p15'>
                <Card title={item.tipo} header={header(item)}  className="alert-list-card md:w-25rem">
                  <div className='col-12'>
                    <p>
                        <button className='btn btn-primary btn-open' onClick={() => {handleOpenButton(item)}}>Abrir <FontAwesomeIcon icon={faBell}></FontAwesomeIcon></button>
                    </p>
                  </div>
                    
                </Card>
              </div> 
              </>) : ''
          }
        </>
     </div> 
     
  
     </div>
  );
}

export default AlertList;
