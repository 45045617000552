import axios from '../axios/axiosConfig';
import {appConfig} from '../shared/config'

export interface HistoryAlert {
  id: any,
  observacao: string,
  acao: string,
  tipo: string,
  mail: string,
  dt_alerta: Date,
  anexos: any[]
}

export interface HistoryAlertFilter {
  obs: string,
  acao: string,
  tipo: string,
  date: Date | undefined
}

export const useHistoryAlertApi = () => {
    const url = appConfig.backend_url + 'history'

    const createHistoryDataRow = async (body: HistoryAlert): Promise<any> => {
        let result = null
        const responseData = await axios.post(url, body)
        result = responseData 
        
        return result
    }

    const queryAlert = async (id: any): Promise<any> => {
      let result = null
      const responseData = await axios.get(`${url}/${id}`)
      result = responseData 
      
      return result
  }

    return {
      createHistoryDataRow,
      queryAlert
    }
}

const construirURL = (filtro: HistoryAlertFilter) => {
  let params = '?'
  if (filtro.acao) {
    params += `acao=${filtro.acao}&`;
  }

  if (filtro.obs) {
    params += `obs=${filtro.obs}&`;
  }

  if (filtro.date) {
    params += `date=${filtro.date}&`;
  }

  if (filtro.tipo) {
    params += `tipo=${filtro.tipo}&`;
  }

  // Remova o último "&" da URL, se houver
  if (params.endsWith('&') || params.endsWith('?')) {
    params = params.slice(0, -1);
  } 
  return params;
};

export const useQuerySearchAlertApi = () => {
  const url = appConfig.backend_url + 'history'

  const querySearchAlertApiData = async (query: HistoryAlertFilter): Promise<any> => {
      let result = null
      const responseData = await axios.get(url + construirURL(query))
      result = responseData 
      
      return result
  }

  return {
    querySearchAlertApiData
  }
}