import React, { useEffect, useState } from 'react';
import './History.css';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from './components/SearchBar';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import { HistoryAlertFilter, useQuerySearchAlertApi } from '../../hooks/historyAlertApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faHandPointer, faTrash } from '@fortawesome/free-solid-svg-icons';
import { eventBus } from '../../shared/eventBus';
import { format } from 'date-fns';

function History() {
  const navigate = useNavigate()
  const  [imgs, setImgs] =  useState([])
  const {querySearchAlertApiData} = useQuerySearchAlertApi()

  const header = (img: any) => (
    <>
    {
      img.history_alert_anexos && img.history_alert_anexos.length > 0
      ?
      <img alt="Card" className='img-list h245' src={img.history_alert_anexos[img.history_alert_anexos.length - 1].face} />
      : 
      <FontAwesomeIcon icon={faBan} className='img-out'></FontAwesomeIcon>
    }
    </>
    )

  const footer = (id: any) => (
    <>

      <div className='flex full-centered'>
        <button className='btn-mais-detalhes btn btn-primary'>
          <div className='flex-button footer-detalhes' onClick={() => navigate('/auditorias/' + id)}>
            <span>Mais detalhes</span>
            <FontAwesomeIcon icon={faHandPointer}></FontAwesomeIcon>
          </div>
        </button>
       
        {/*
        <button className='btn-excluir btn btn-danger'>
          <div className='flex-button footer-detalhes'>
            <span>Remover</span>
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </div>
        </button>
        */}
        
      </div>
      
    </>
  )

  useEffect(() => {
    const filtro: HistoryAlertFilter = {
      obs: '',
      acao: '',
      tipo: '',
      date: undefined
    }
    search(filtro)

    eventBus.on('search-bar', async (filtro: any) => {
      await search(filtro) 
    })
    setImgs([])
  }, []);

  const search = async (filtro: HistoryAlertFilter) => {
    try {
      eventBus.dispatch('loader', true)
      const result = await querySearchAlertApiData(filtro)
      eventBus.dispatch('loader', false)
      setImgs(result.data.body)
    } catch (error) {
      eventBus.dispatch('loader', false)
      console.log(error)
    }
  }
  
  return (
    <div className="App" style={{'height': '100%'}}>
        <SearchBar></SearchBar>

        <div className='col-12'>
          <h1 className='title-list'>Histórico de Eventos</h1>
        </div>
        <div id='history-list-container' className='col-12 history-list-container'>
          <> 
            {
              imgs && imgs.length > 0 ? (imgs as any[]).map((item: any) => <>

              <div key={item.id} style={{'width': '400px'}}>
                <Card onClick={() => {console.log(item)}} id='history-card' footer={() => {
                  return footer(item.id)
                }} title={<><span><>{item.acao}</></span> <span><>{ format(new Date(item.dt_alerta), 'dd/MM/yyyy HH:mm')}</></span></>} subTitle={item.tipo} header={header(item)}  className="history md:w-25rem">
                  
                  <div className='col-12'>
                    <p>
                        <span className='span-obs'><>{item.observacao}</></span>
                    </p>
                  </div>
                    
                </Card>
              </div>
              
              </>) : ''
            }
          </>
        </div>
        
    </div>
  );
}

export default History;
