import axios from 'axios';
import {appConfig} from '../shared/config'
import { eventBus } from '../shared/eventBus';

const instance = axios.create({
  baseURL:  appConfig.backend_url,
});

// Adicione um interceptor que será executado antes de cada solicitação
instance.interceptors.request.use(function (config) {
    config.headers['AUTH'] = localStorage.getItem('login_data')
    config.headers['USER'] = localStorage.getItem('user')
    return config;
  }, function (error) { 
    return Promise.reject(error);
});

instance.interceptors.response.use(function (config) {
  return config;
}, function (error) { 

  if (error.response.status === 401) {
    eventBus.dispatch('login', null)
  }

  
  return Promise.reject(error);
});

export default instance;
