import axios from '../axios/axiosConfig';

export const useAuditoriasApi = () => {
    const url = 'history-auditorias/'

    const search = async (alertId: number): Promise<any> => {
        let result = null
        const responseData = await axios.get(url + alertId)
        result = responseData 
        return result
    }

    const enviaNovaObs = async (alertId: any, novaObs: string): Promise<any> => {
      let result = null
      const responseData = await axios.post(url + 'novo/' + alertId + '/' + novaObs)
      result = responseData 
      return result
  }

    return {
      search, enviaNovaObs
    }
}  